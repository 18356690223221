// General
import { graphql, navigate } from 'gatsby';
import { ALLOW_INSURED_BIRDI_PRICE, ENABLE_AUTO_REFILL } from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

// Ui-kit
import NoRefillsIcon from 'ui-kit/illustrations/no-refills/no-refills';
import PageSection from 'ui-kit/page-section/page-section';
import Spinner from 'ui-kit/spinner/spinner';

// Display components
import EmptyState from 'display-components/empty-state/empty-state';

//Components
import AutoRefillBanner from 'components/auto-refill-banner/auto-refill-banner.component';
import { AutoRefillTermsAndConditionsModal } from 'components/auto-refill-terms-and-conditions-modal';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import InternalHeader from 'components/internal-header/internal-header.component';
import MarketingPageLayout from 'components/layouts/page/marketingpage.layout';
import PrescriptionTabs from 'components/prescriptions-list-v2/prescription-tabs/prescription-tabs.component';
import { easyRefillPrescriptionPayloadToProps } from 'components/prescriptions-list-v2/prescriptions-card/easy-refill-payload-to-props';
import EasyRefillPrescriptionCardV2Mobile from 'components/prescriptions-list-v2/prescriptions-card/easy-refill-prescriptions-card-mobile.component';
import EasyRefillPrescriptionCardV2Desktop from 'components/prescriptions-list-v2/prescriptions-card/easy-refill-prescriptions-card.component';
import { EasyRefillPrescriptionCardProps } from 'components/prescriptions-list-v2/prescriptions-card/types';
import RefillDetailsCart from 'components/refill-details-cart/refill-details-cart';

// States
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { drugDiscountPriceRoutine } from 'state/drug/drug.routines';
import { drugSelector } from 'state/drug/drug.selectors';
import {
    easyRefillFetchHealthConditionsRoutine,
    easyRefillGetCartRoutine,
    easyRefillGetPatientAddressesRoutine,
    easyRefillGetPatientDataRoutine,
    easyRefillGetPatientPaymentCardsRoutine,
    easyRefillGetPatientPlansRoutine,
    easyRefillStartOrderRoutine
} from 'state/easy-refill/easy-refill.routines';
import {
    autoRefillToggleBusySelector,
    cartSelector,
    easyRefillAccountHasInsuranceSelector,
    easyRefillAccountIsCaliforniaUserSelector,
    easyRefillAddressesSelector,
    easyRefillCartItemsSelector,
    easyRefillEligibleRxsCaregiverSelector,
    easyRefillEligibleRxsSelector,
    easyRefillEpostPatientNumSelector,
    easyRefillExpiredSelector,
    easyRefillFamilyDependentsSelector,
    easyRefillFirstNameSelector,
    easyRefillIsCaregiverSelector,
    easyRefillLoadingCartSelector,
    easyRefillLoadingRxsSelector,
    easyRefillOrderAddressSelector,
    easyRefillPatientAddressSelector,
    easyRefillPatientAutoRefillSelector,
    easyRefillPaymentCardsSelector,
    easyRefillPlanAliasSelector,
    easyRefillPlansSelector,
    easyRefillRemoveCartBtnDisabled,
    easyRefillRxsLoadedSelector,
    easyRefillRxsSelector,
    easyRefillRxsToRefillSelector,
    easyRefillSelector,
    easyRefillToggleBusySelector,
    easyRefillUserBearerTokenSelector
} from 'state/easy-refill/easy-refill.selectors';

// Interfaces and Types
import {
    CreateOrUpdateResponse,
    EasyRefillPatientDataCaregiver,
    EasyRefillRxResult,
    RefillRx
} from 'types/easy-refill';

// Util
import { lowercaseAndCapitalize } from 'util/cart';
import { mapEasyRefillFamilyMembers, processEasyRefillCart } from 'util/easy-refill';
import { getPhoneNumber } from 'util/globalVariables';
import { TrackCheckoutStep } from 'util/google_optimize/optimize_helper';
import storageHelper from 'util/storageHelper';
import { capitalizeFirstLetter } from 'util/string';

// Hooks
import { useAutoRefillToggle } from 'hooks/useAutoRefillToggle';
import { useEasyRefillHealthConditions } from 'hooks/useEasyRefillHealthConditions';
import { useUnAuthAutoRefill } from 'hooks/useUnAuthAutoRefill';

// HOC
import withUnauthenticatedSessionExpiration from 'hoc/withUnauthenticatedSessionExpiration';

import { EasyRefillErrorModal } from '..';
import './index.style.scss';

// Main component
const EasyRefillPrescriptions = ({
    data
}: {
    data: GatsbyTypes.GetEasyRefillPrescriptionLanguageAndImageDataQuery;
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { blueSkyBackground } = data;

    // --------------------
    // Selectors
    // -------------------
    const firstName = useSelector(easyRefillFirstNameSelector);
    const easyRefillLoadingRxs = useSelector(easyRefillLoadingRxsSelector);
    const easyRefillBearerToken = useSelector(easyRefillUserBearerTokenSelector);
    const easyRefillRxsLoaded = useSelector(easyRefillRxsLoadedSelector);
    const easyRefillRxs = useSelector(easyRefillRxsSelector);
    const accountHasInsured = useSelector(easyRefillAccountHasInsuranceSelector);
    const easyRefillEPostPatientNumber = useSelector(easyRefillEpostPatientNumSelector);
    const autoRefillEligibleRxs = useSelector(easyRefillEligibleRxsSelector);
    const autoRefillEligibleCaregiverRxs = useSelector(easyRefillEligibleRxsCaregiverSelector);
    const easyRefillRxsToRefill = useSelector(easyRefillRxsToRefillSelector); // This selector should be used as the cart simulation.
    const easyRefillIsCaregiver = useSelector(easyRefillIsCaregiverSelector);
    const easyRefillFamilyDependents = useSelector(easyRefillFamilyDependentsSelector);
    const easyRefill = useSelector(easyRefillSelector);
    const easyRefillCartItems = useSelector(easyRefillCartItemsSelector);
    const easyRefillIsBusy = useSelector(autoRefillToggleBusySelector);
    const easyRefillCartLoading = useSelector(easyRefillLoadingCartSelector);
    const { drugDiscountPrices } = useSelector(drugSelector);
    const isCaliforniaUser = useSelector(easyRefillAccountIsCaliforniaUserSelector);
    const isAutoRefillToggleBusy = useSelector(easyRefillToggleBusySelector);
    const removeCartBtnDisabled = useSelector(easyRefillRemoveCartBtnDisabled);
    const isExpired = useSelector(easyRefillExpiredSelector);
    const accountPlans = useSelector(easyRefillPlansSelector);
    const cartsObject = useSelector(cartSelector);
    const planAlias = useSelector(easyRefillPlanAliasSelector);
    const orderShippingAddress = useSelector(easyRefillOrderAddressSelector);
    const patientPaymentCards = useSelector(easyRefillPaymentCardsSelector);
    const patientAddressses = useSelector(easyRefillAddressesSelector);
    const patientDefaultAddress = useSelector(easyRefillPatientAddressSelector);
    const userAutoRefill = useSelector(easyRefillPatientAutoRefillSelector);
    const healthConditions = useEasyRefillHealthConditions();
    const { rxAutoRefillEnabled, rxAutoRefillEligible } = useUnAuthAutoRefill();
    const { handleToggleAutoRefill } = useAutoRefillToggle();

    // --------------------
    // Local state
    // -------------------
    const [orderedEasyRefillRxs, setOrderedEasyRefillRxs] = useState<EasyRefillRxResult[]>([]);
    const [processedRxs, setProcessedRxs] = useState<EasyRefillPrescriptionCardProps[]>([]);
    const [activeDependentTab, setActiveDependentTab] = useState<string | null>(null);
    const [isRxBusy, setIsRxBusy] = useState<boolean>(false);
    const [planAllowsAutoRefill, setPlanAllowsAutoRefill] = useState<boolean>(false);

    // --------------------
    // Memoized values
    // -------------------

    const isMembership: boolean = useMemo(() => planAlias === 'BRD02', [planAlias]);
    const currentZipCode: string = useMemo(
        () => orderShippingAddress?.zipcode || patientDefaultAddress?.zipcode || '',
        [orderShippingAddress, patientDefaultAddress]
    );

    const shouldDisplayAutoRefillInfo = useMemo(() => {
        if (easyRefillIsCaregiver) {
            return easyRefillFamilyDependents.some((dependent) =>
                dependent.autoRefillFlags?.some(
                    (flag) => flag.planAutoRefillEligible && activeDependentTab === dependent.epostPatientNum
                )
            );
        } else {
            return easyRefill.autoRefillFlags?.some((flag) => flag.planAutoRefillEligible);
        }
    }, [easyRefill, easyRefillFamilyDependents, easyRefillIsCaregiver, activeDependentTab]);

    // This function checks whether the account has dependents, ensures a match for each one,
    // and retrieves the epostPatientNum to make a specific API call.
    const familyMembers = useMemo(() => {
        if (easyRefillFamilyDependents.length > 0) {
            const familyMembers = mapEasyRefillFamilyMembers(easyRefillFamilyDependents, true);
            return familyMembers;
        } else {
            return null;
        }
    }, [easyRefillFamilyDependents]);

    // --------------------
    // Callbacks
    // -------------------

    // Display error modal
    const handleShowErrorModal = useCallback(
        (errorText: string) => {
            dispatch(
                openModal({
                    showClose: true,
                    size: 'lg',
                    type: 'danger',
                    headerContent: (
                        <BirdiModalHeaderDanger headerText={t('modals.easyRefillFailure.title')} icon="alert" />
                    ),
                    bodyContent: <EasyRefillErrorModal translation={t} errorMessage={errorText} />,
                    ctas: [
                        {
                            label: t('modals.easyRefillFailure.buttonText'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(closeModal({}));
                            },
                            dataGALocation: 'EasyRefillNotVerified'
                        }
                    ]
                })
            );
        },
        [dispatch, t]
    );

    const handleAddToCartClick = (rxNumber: string, epostPatientNum: string) => {
        setIsRxBusy(true);
        // Convert variables to strings if they are not already strings
        rxNumber = typeof rxNumber !== 'string' ? String(rxNumber) : rxNumber;
        epostPatientNum = typeof epostPatientNum !== 'string' ? String(epostPatientNum) : epostPatientNum;

        // Check if the item is already in the cart
        const rxInCart = easyRefillCartItems?.some((cartItem) => cartItem.rxNumber === rxNumber);
        if (!rxInCart) {
            dispatch(
                easyRefillStartOrderRoutine.trigger({
                    rxNumber: rxNumber,
                    epostPatientNum: epostPatientNum,
                    onSuccess: () => {
                        setIsRxBusy(false);
                        const extendedCart = processEasyRefillCart(
                            cartsObject,
                            isMembership || accountHasInsured,
                            easyRefillRxs,
                            drugDiscountPrices,
                            accountPlans,
                            currentZipCode
                        );
                        // DRX-2008: Validate if this helper could be usable as the authenticated Cart.
                        TrackCheckoutStep({
                            stepName: 'add',
                            step: '1',
                            carts: extendedCart,
                            prescriptions: easyRefillRxs,
                            t: t,
                            shippingCost: '0',
                            accountHasInsurance: accountHasInsured
                        });
                        dispatch(
                            easyRefillGetCartRoutine.trigger({
                                onSuccess: () => {
                                    dispatch(closeModal({}));
                                }
                            })
                        );
                        dispatch(easyRefillGetPatientDataRoutine.trigger({ withoutLoading: true }));
                    },
                    onFailure: () => {
                        setIsRxBusy(false);
                        dispatch(
                            openModal({
                                showClose: true,
                                bodyContent: (
                                    <BirdiModalContent
                                        icon={'alert'}
                                        title={t(`pages.medicineCabinet.messages.callbacks.error`)}
                                        body={t(`pages.medicineCabinet.messages.callbacks.errorMessage`)}
                                    />
                                ),
                                ctas: [
                                    {
                                        label: t(`pages.medicineCabinet.messages.labels.gotIt`),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        }
                                    }
                                ]
                            })
                        );
                    }
                })
            );
        }
    };

    // Handle Auto refill toggle click
    const onChangeAutoRefill = useCallback(
        (rx: EasyRefillRxResult, autoRefillEnabled: boolean, isRenew?: boolean) => {
            const hasAnyRxAutorefillOn = easyRefill.autoRefillFlags?.some((rx) => rx.autoRefillEnabled);
            handleToggleAutoRefill(
                { rxNumber: rx.rxNumber, rxSeqNum: rx.rxSeqNum },
                autoRefillEnabled,
                hasAnyRxAutorefillOn,
                <AutoRefillTermsAndConditionsModal t={t} />,
                isRenew
            );
        },
        [handleToggleAutoRefill, t, easyRefill.autoRefillFlags]
    );

    // Function that handles a tab change
    // TODO: When refactoring consider another approach to don't need to
    // pass an unused tab property.
    const handleTabItemClick = (tab = 'all', dependent: string | null) => {
        setActiveDependentTab(dependent);
    };

    const formatFamilyMembersData = (data: EasyRefillPatientDataCaregiver[]) => {
        return (
            data.length > 0 &&
            data.map((item, key) => ({
                ePostPatientNum: item.epostPatientNum,
                familyMemberName: lowercaseAndCapitalize(`${item.firstName || ''} ${item.lastName || ''}`),
                id: key
            }))
        );
    };

    const processedGreeting = useCallback(() => {
        if (firstName) {
            const hasRxs = easyRefillIsCaregiver
                ? easyRefillFamilyDependents.some((dependent) => dependent.rxResults?.length > 0)
                : easyRefillRxs.length > 0;
            const capitalizedFirstName = capitalizeFirstLetter(firstName.toLocaleLowerCase());
            return hasRxs ? (
                <p>
                    {t('pages.easyRefill.prescriptions.greeting', {
                        firstName: capitalizedFirstName
                    })}
                </p>
            ) : (
                <p>
                    {t('pages.easyRefill.prescriptions.greetingEmpty', {
                        firstName: capitalizedFirstName
                    })}
                </p>
            );
        }
        return <p>{t('pages.easyRefill.prescriptions.greetingEmptyNoName')}</p>;
    }, [easyRefillLoadingRxs]);

    // Function that renders Rxs for family members.
    const renderFamilyPrescriptions = useCallback(
        (prescriptions: EasyRefillPrescriptionCardProps[] | null, dependent: string | null) => {
            return (
                <>
                    {prescriptions?.length === 0 ? (
                        <div className="empty-state-container">
                            <EmptyState
                                icon={NoRefillsIcon}
                                title={t('components.emptyState.noRefills.title')}
                                description={t('components.emptyState.noRefills.description')}
                            />
                        </div>
                    ) : (
                        prescriptions &&
                        prescriptions.map((prescription, index) => (
                            <Col
                                className="p-0 mb-4 flex-fill auto-refill-rx-card"
                                key={`auto-refill-rx-card-${index}`}
                            >
                                <div className="d-md-none">
                                    <EasyRefillPrescriptionCardV2Mobile
                                        {...prescription}
                                        autoRefillEligibleForRefill={rxAutoRefillEligible(
                                            prescription.rxNumber,
                                            dependent
                                        )}
                                        autoRefillEnabledForRx={rxAutoRefillEnabled(prescription.rxNumber, dependent)}
                                        autoRefillOnChange={onChangeAutoRefill}
                                        isCaliforniaUser={isCaliforniaUser}
                                        autoRefillBusy={easyRefillIsBusy}
                                        autorefillChange={
                                            easyRefillCartLoading || isAutoRefillToggleBusy || removeCartBtnDisabled
                                        }
                                        isRxBusy={isRxBusy}
                                        zipCode={currentZipCode}
                                        planAllowsAutoRefill={planAllowsAutoRefill}
                                    />
                                </div>
                                <div className="d-none d-md-flex">
                                    <EasyRefillPrescriptionCardV2Desktop
                                        {...prescription}
                                        autoRefillEligibleForRefill={rxAutoRefillEligible(
                                            prescription.rxNumber,
                                            dependent
                                        )}
                                        autoRefillEnabledForRx={rxAutoRefillEnabled(prescription.rxNumber, dependent)}
                                        autoRefillOnChange={onChangeAutoRefill}
                                        isCaliforniaUser={isCaliforniaUser}
                                        autoRefillBusy={easyRefillIsBusy}
                                        autorefillChange={easyRefillCartLoading || isAutoRefillToggleBusy}
                                        isRxBusy={isRxBusy}
                                        zipCode={currentZipCode}
                                        planAllowsAutoRefill={planAllowsAutoRefill}
                                    />
                                </div>
                            </Col>
                        ))
                    )}
                </>
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            easyRefillRxsToRefill,
            easyRefillIsBusy,
            easyRefillCartLoading,
            isAutoRefillToggleBusy,
            removeCartBtnDisabled,
            isRxBusy,
            planAllowsAutoRefill
        ]
    );

    const updateAndSortPrescriptions = (
        prescriptions: EasyRefillRxResult[],
        cartItems: RefillRx[],
        easyRefillRxsToRefill: string[]
    ) => {
        return prescriptions
            .map((prescription) => {
                const { rxNumber, prescriptionOldData = {} } = prescription;
                const isInCart = cartItems.find((rx) => rx.rxNumber === rxNumber);
                return isInCart
                    ? { ...prescription, inOrderCart: true, prescriptionOldData: { ...prescription } }
                    : { ...prescription, ...prescriptionOldData, inOrderCart: false };
            })
            .sort((a, b) => {
                if (a.inOrderCart !== b.inOrderCart) {
                    return a.inOrderCart ? -1 : 1;
                }
                return easyRefillRxsToRefill.includes(a.rxNumber) ? -1 : 1;
            });
    };

    // --------------------
    // Use effect
    // -------------------

    // Check if the token is valid
    useEffect(() => {
        if (isExpired || !easyRefillBearerToken) {
            navigate('/link-expired?flow=easy-refill');
        }
    }, [easyRefillBearerToken, isExpired]);

    // DRX-3165: This useEffect handles post-order actions in Easy Refill.
    // It verifies if an order has been placed by checking a specific cookie.
    // If an order exists, it dispatches actions to fetch patient data and cart details,
    // then removes the order placement cookie to prevent redundant triggers.
    useEffect(() => {
        const hasPlacedAnOrder = storageHelper.cookies.getHasOrderEasyRefillPlaced();

        if (hasPlacedAnOrder) {
            dispatch(easyRefillGetPatientDataRoutine.trigger({}));
            dispatch(easyRefillGetCartRoutine.trigger());
            storageHelper.cookies.removeHasOrderEasyRefillPlaced();
        }
    }, [dispatch]);

    /***************************************************************************************************/

    /************************************  Patient Data Api Call   ************************************/

    // DRX-2425: Due to various distinct requirements causing a potential performance loss
    // when the flow is invoked multiple times, this useEffect is placed at the top
    // to fetch the information once.

    useEffect(() => {
        if (easyRefillRxsLoaded) return;

        // Get Patient Data
        // do not call the patient data if the rxResults already populated

        if (easyRefillEPostPatientNumber) setActiveDependentTab(easyRefillEPostPatientNumber);
        dispatch(
            easyRefillGetPatientDataRoutine.trigger({
                onFailure: (error: Pick<CreateOrUpdateResponse, 'messageText'>) => {
                    handleShowErrorModal(
                        t('pages.easyRefill.prescriptions.errorRetrievingData', {
                            phoneNumber: getPhoneNumber({ isEnd: true })
                        })
                    );
                }
            })
        );

        if (patientAddressses.length === 0) dispatch(easyRefillGetPatientAddressesRoutine.trigger());
        if (patientPaymentCards.length === 0) dispatch(easyRefillGetPatientPaymentCardsRoutine.trigger());
        if (!healthConditions.ePostPatientNum) dispatch(easyRefillFetchHealthConditionsRoutine.trigger());
        // Start the Cart process by getting all the items from the Easy Caregiver Cart.
        dispatch(easyRefillGetCartRoutine.trigger());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // DRX-2425: Because of variations in response objects for patient data, we need to dispatch API calls differently.
    // In caregiver accounts, the call must be made during the mapping of family members through the familyMembers validation.
    // However, in normal accounts, we directly call the API with the EpostpatientNum for the patient in the flow.
    useEffect(() => {
        if (!accountPlans?.length && familyMembers) {
            dispatch(easyRefillGetPatientPlansRoutine({ familyMembers: familyMembers }));
        } else if (!easyRefillIsCaregiver && !accountPlans?.length && easyRefillEPostPatientNumber) {
            dispatch(easyRefillGetPatientPlansRoutine({ familyMembers: [easyRefillEPostPatientNumber] }));
        }
    }, [
        accountPlans,
        dispatch,
        easyRefillEPostPatientNumber,
        easyRefillIsCaregiver,
        easyRefillRxsLoaded,
        familyMembers
    ]);

    /***************************************************************************************************/

    useEffect(() => {
        if (!easyRefillFamilyDependents.length || !activeDependentTab) {
            setPlanAllowsAutoRefill(userAutoRefill);
            return;
        }

        const activeDependentInfo = easyRefillFamilyDependents.find(
            (dependent) => dependent.epostPatientNum === activeDependentTab
        );

        if (!activeDependentInfo) return;

        setPlanAllowsAutoRefill(!!activeDependentInfo.patientAutoRefill);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [easyRefillFamilyDependents, activeDependentTab]);

    useEffect(() => {
        if (easyRefillFamilyDependents.length && activeDependentTab) {
            const dependent = easyRefillFamilyDependents.find((item) => item.epostPatientNum === activeDependentTab);
            if (!dependent) return;
            if (dependent.rxResults.length === 0) {
                setOrderedEasyRefillRxs([]);
                return;
            }

            const prescriptionsInCart = updateAndSortPrescriptions(
                dependent.rxResults,
                easyRefillCartItems || [],
                easyRefillRxsToRefill
            );

            setOrderedEasyRefillRxs(prescriptionsInCart);
        } else {
            const easyRefillSorted = updateAndSortPrescriptions(
                easyRefillRxs,
                easyRefillCartItems || [],
                easyRefillRxsToRefill
            );

            setOrderedEasyRefillRxs(easyRefillSorted);
        }
    }, [easyRefillFamilyDependents, activeDependentTab, easyRefillRxs, easyRefillRxsToRefill, easyRefillCartItems]);

    // Ensure that we are mapping the Rx only the times needed
    // TODO: THIS WILL NEED TO BE MOVED TO SAGAS
    useEffect(() => {
        if (orderedEasyRefillRxs.length > 0) {
            setProcessedRxs(
                orderedEasyRefillRxs.map((prescription) =>
                    easyRefillPrescriptionPayloadToProps(
                        prescription,
                        t,
                        accountHasInsured,
                        easyRefillRxsToRefill.includes(prescription.rxNumber),
                        () => {
                            handleAddToCartClick(prescription.rxNumber, prescription.epostPatientNum);
                        }
                    )
                )
            );
        } else setProcessedRxs([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderedEasyRefillRxs]);

    // Ensure that pricing is only triggered for the Rxs that are eligible for
    // easy refill but also the ones that the status allow the price to be displayed
    useEffect(() => {
        const easyRefillsElegibleRxs = easyRefillIsCaregiver ? autoRefillEligibleCaregiverRxs : autoRefillEligibleRxs;
        if (processedRxs.length > 0 && easyRefillsElegibleRxs.length > 0) {
            let birdiPricePrescriptions: EasyRefillPrescriptionCardProps[] = processedRxs
                .filter((rx) => easyRefillsElegibleRxs.find((eligibleRx) => eligibleRx.rxNumber === rx.rxNumber))
                .filter((rx) => rx.showPrice);
            // DRX-583 - get prices for Insured users to pre-fetch for cart. We only need to get
            // prices for items in cart.
            if (accountHasInsured && ALLOW_INSURED_BIRDI_PRICE) {
                // DRX-996 - Since we have to show a different message when an item is added to the cart,
                // we need to get all Rxs that are in the cart, or could be added to the cart.
                birdiPricePrescriptions = birdiPricePrescriptions.filter((rx) => {
                    return (
                        rx.fullRxItem.inOrderCart === true ||
                        rx.fullRxItem.webEligibilityStatus === 'ELIGIBLE' ||
                        rx.fullRxItem.webEligibilityStatus === 'AUTH_REQ'
                    );
                });
            } else {
                // No need to get "pending" prescriptions for Birdi price users
                if (!accountHasInsured) {
                    birdiPricePrescriptions = birdiPricePrescriptions.filter(function (rx) {
                        return !!(rx.fullRxItem.dispensedProductNumber || rx.fullRxItem.writtenProductNumber);
                    });
                }
            }

            dispatch(
                drugDiscountPriceRoutine.trigger({
                    prescriptions: birdiPricePrescriptions.map((rx) => rx.fullRxItem),
                    forceBirdiInsurance: !!(accountHasInsured && ALLOW_INSURED_BIRDI_PRICE),
                    location: 'Cart'
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [processedRxs]);

    return (
        <MarketingPageLayout
            headerImage={blueSkyBackground}
            headerImageClassName="profile-background"
            metaData={{ nodeTitle: 'Easy Refill' }}
            suppressApplicationPage={true}
        >
            <PageSection>
                <Container fluid className="easy-refill-prescriptions">
                    <InternalHeader
                        title={t('pages.easyRefill.prescriptions.headlineText')}
                        eyebrowText={t('pages.easyRefill.prescriptions.eyebrowText')}
                        sectionIndex={0}
                        body={
                            easyRefillLoadingRxs ? (
                                <Spinner isVisible={easyRefillLoadingRxs} t={t} />
                            ) : (
                                processedGreeting()
                            )
                        }
                    />

                    <div className="easy-refill-prescriptions-content">
                        <div className="easy-refill-prescriptions-content-prescriptions">
                            {ENABLE_AUTO_REFILL &&
                                shouldDisplayAutoRefillInfo &&
                                !easyRefillLoadingRxs &&
                                planAllowsAutoRefill && <AutoRefillBanner />}
                            {/* Select all Rxs normal patients */}

                            {/* DRX-1760: This will we a future implementation */}

                            {/* {!easyRefillLoadingRxs && !easyRefillIsCaregiver && (
                                    <Row>
                                        <Checkbox
                                            id="select-all-checkbox"
                                            label={t('pages.easyRefill.prescriptions.refillAllPrescriptions')}
                                            onClick={() => {}}
                                            onCheckChanged={() => {}}
                                        />
                                    </Row>
                                )} */}
                            {!easyRefillLoadingRxs && easyRefillIsCaregiver && (
                                <>
                                    <Col xs={12} md={12} lg={12}>
                                        <Row>
                                            <Col className="easy-refill-prescription-tabs" xs={12} md={12} lg={12}>
                                                {easyRefillFamilyDependents.length > 0 && (
                                                    <PrescriptionTabs
                                                        activeTab={'all'}
                                                        activeDependentTab={
                                                            activeDependentTab || easyRefillEPostPatientNumber
                                                        }
                                                        isPrescriptionsAvailable={false}
                                                        onTabItemChange={handleTabItemClick}
                                                        dependents={
                                                            formatFamilyMembersData(easyRefillFamilyDependents) || []
                                                        }
                                                        myEpostPatientNum={easyRefillEPostPatientNumber}
                                                        hasFilterTabs={false}
                                                        hasAutoRefillFlag={false}
                                                    />
                                                )}
                                            </Col>

                                            {/* Select all Rxs for Caregivers */}

                                            {/* DRX-1760: This will we a future implementation */}

                                            {/* {!easyRefillLoadingRxs && easyRefillIsCaregiver && (
                                                    <Row className="w-100 easy-refill-select-all">
                                                        <Checkbox
                                                            id="select-all-checkbox"
                                                            label={t(
                                                                'pages.easyRefill.prescriptions.refillAllPrescriptions'
                                                            )}
                                                            onClick={() => {}}
                                                            onCheckChanged={() => {}}
                                                        />
                                                    </Row>
                                                )} */}

                                            {!easyRefillLoadingRxs &&
                                                !isExpired &&
                                                renderFamilyPrescriptions(processedRxs, activeDependentTab)}
                                        </Row>
                                    </Col>
                                </>
                            )}
                            {!easyRefillLoadingRxs && !easyRefillIsCaregiver && !isExpired && (
                                <>
                                    {processedRxs && processedRxs.length > 0 ? (
                                        <>
                                            {processedRxs.map((prescription, index) => (
                                                <Col
                                                    className="p-0 mb-4 flex-fill auto-refill-rx-card"
                                                    key={`auto-refill-rx-card-${index}`}
                                                >
                                                    <div className="d-md-none">
                                                        <EasyRefillPrescriptionCardV2Mobile
                                                            {...prescription}
                                                            autoRefillEligibleForRefill={rxAutoRefillEligible(
                                                                prescription.rxNumber
                                                            )}
                                                            autoRefillEnabledForRx={rxAutoRefillEnabled(
                                                                prescription.rxNumber
                                                            )}
                                                            autoRefillOnChange={onChangeAutoRefill}
                                                            autoRefillBusy={easyRefillIsBusy}
                                                            autorefillChange={
                                                                easyRefillCartLoading || isAutoRefillToggleBusy
                                                            }
                                                            isCaliforniaUser={isCaliforniaUser}
                                                            isRxBusy={isRxBusy}
                                                            zipCode={currentZipCode}
                                                            planAllowsAutoRefill={planAllowsAutoRefill}
                                                        />
                                                    </div>
                                                    <div className="d-none d-md-flex">
                                                        <EasyRefillPrescriptionCardV2Desktop
                                                            {...prescription}
                                                            autoRefillEligibleForRefill={rxAutoRefillEligible(
                                                                prescription.rxNumber
                                                            )}
                                                            autoRefillEnabledForRx={rxAutoRefillEnabled(
                                                                prescription.rxNumber
                                                            )}
                                                            autoRefillOnChange={onChangeAutoRefill}
                                                            autoRefillBusy={easyRefillIsBusy}
                                                            autorefillChange={
                                                                easyRefillCartLoading || isAutoRefillToggleBusy
                                                            }
                                                            isCaliforniaUser={isCaliforniaUser}
                                                            isRxBusy={isRxBusy}
                                                            zipCode={currentZipCode}
                                                            planAllowsAutoRefill={planAllowsAutoRefill}
                                                        />
                                                    </div>
                                                </Col>
                                            ))}
                                        </>
                                    ) : (
                                        <div className="easy-refill-prescriptions-empty">
                                            <EmptyState
                                                icon={NoRefillsIcon}
                                                title={t('components.emptyState.noRefills.title')}
                                                description={t('components.emptyState.noRefills.description')}
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                        {!easyRefillLoadingRxs && <RefillDetailsCart />}
                    </div>
                </Container>
            </PageSection>
        </MarketingPageLayout>
    );
};

export default withUnauthenticatedSessionExpiration(EasyRefillPrescriptions, 'easy-refill');

export const query = graphql`
    query GetEasyRefillPrescriptionLanguageAndImageData($language: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        blueSkyBackground: file(relativePath: { eq: "assets/images/white-feathers-background-02.png" }) {
            id
            childImageSharp {
                fluid {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid
                }
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
